.page.login {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 1fr auto 1fr;
    min-height: 100vh;
}

.page.login form {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}