.input {
    margin-bottom: 1rem;
    font-family: 'Noto Sans', sans-serif;
}

.input label {
    display: block;
    width: 100%;
    margin-bottom: .5rem;
}

.input input {
    display: block;
    width: 100%;
}