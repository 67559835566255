.unread {
    font-weight: bold;
}

table tbody tr:nth-child(2n+1) {
    background: antiquewhite;
}

table tbody td {
    padding: .25rem 1rem;
}