article {
    width: 55ch;
    margin: 0 auto; /* Old but still good */
    font-size: 1.25rem;
    font-family: 'Merriweather', serif;
}

article h1 {
    font-size: 4rem;
}

article h2 {
    font-size: 3rem;
}

article img {
    width: auto;
    height: auto;
    max-width: 55ch;
    margin: 0 auto;
}

article audio,
article video {
    width: 100%;
}