nav ul {
    list-style: none;
    display: flex;
    gap: 1rem;
    padding: 1rem 0;
    margin: 0;
}

nav ul li a {
    padding: .5rem 1rem;
}

nav .active {
    background: #005C8F;
    color: white;
}

nav ul li a:hover,
nav ul li a:active {
    background: #005C8F;
    color: white;
}